/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "blustreamREST",
            "endpoint": "https://pfvfhpura6.execute-api.us-east-1.amazonaws.com/develop",
            "region": "us-east-1"
        },
        {
            "name": "publicshorturl",
            "endpoint": "https://7igicdm5m8.execute-api.us-east-1.amazonaws.com/develop",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://ccuxt5w2vnfjjkuqam4nkmrmvi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-tgg7bjokzzbxhosqd76y7a526a",
    "aws_cognito_identity_pool_id": "us-east-1:92c9614a-235f-4ada-807e-d615eba7a43a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_gDr8QyTvh",
    "aws_user_pools_web_client_id": "7cn4vu8br0ak0bvl3g8mmlodrk",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "blustreamfrontend-20211126150112-hostingbucket-develop",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2h1ozsv5nvsvx.cloudfront.net",
    "aws_user_files_s3_bucket": "blustreamfrontenda4c26b55bbdb40489dfdc2867d6081194441-develop",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
